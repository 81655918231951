/** * 解决方案  */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/3D-SES-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">创意就是一切</p>
            <p class="banner-text">创意 + 简单易用的工具，创造无限可能</p>
          </div>
      </v-img>
    </v-banner>
    
    <!-- 搭建属于你的场景 -->
    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="public-title">
          <p>BUILD YOUR OWN SCENE</p>
          <div>
            <span>搭建属于你的场景</span>
          </div>
        </div>
        <div class="d-flex justify-center">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">场景随手就来</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">超低门槛的图形化场景编辑器，随手就能搭建你想要的场景，比如房屋，比如展厅，比如游戏地图</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                   max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/3D-SES01.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>
    <!-- 动画也太酷了吧 -->
    <div class="d-flex justify-center width-per-100 background-F5F9FD pb-15">
      <v-card class="max-width-1200 width-per-100" flat color="transparent">
        <div class="public-title">
          <p>IS ANIMATION COOL</p>
          <div>
            <span>动画也太酷了吧</span>
          </div>
        </div>
        <div class="d-flex justify-center">
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                   max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/3D-SES02.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <div style="margin-left: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">动画随心而动</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">为你的场景加入一点特效，为你的游戏加入一点闪亮的元素，让游戏更好玩</div>
                </v-card-text>
              </div>
            </div>
          </transition>
        </div>
      </v-card>
    </div>
    <!-- 创造属于你的游戏 -->
    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="public-title">
          <p>CREATE YOUR OWN GAME</p>
          <div>
            <span>创造属于你的游戏</span>
          </div>
        </div>
        <div class="d-flex justify-center">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">有创意就有实力</v-card-title>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">借助场景搭建工具及动画制作工具，配合基础的代码编写能力，制作属于你的第一款游戏吧</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                   max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/3D-SES03.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'Solution',
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  
}
</style>
